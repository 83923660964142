 
  <template>
  <div class="cont">
    <!-- <hr /> -->
    <ul id="toc" class="right" style=" padding: 15px">
      <li
        class="heading1"
        id="i1"
        style="background: url(cicon9.png) no-repeat; padding-left: 20px"
        onclick="return clicked(this,event)"
      >
        <a class="heading1" id="a1" @click="Jump('智荟网')" target="hmcontent">
          <span class="heading1" id="s1">智荟网</span>
        </a>
      </li>
      <li
        class="heading1"
        id="i2"
        data-bg="cicon1.png;cicon2.png"
        style="
          background: url(cicon2.png) no-repeat;
          cursor: pointer;
          padding-left: 20px;
        "
        onclick="return clicked(this,event)"
      >
        <a class="heading1" id="a2" @click="Jump('知识头条')" target="hmcontent">
          <span class="heading1" id="s2">知识头条</span>
        </a>
        <ul id="ul2" style="list-style-type: none">
          <li
            class="heading2"
            id="i2.1"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a2.1" @click="Jump('如何发布文章')" target="hmcontent">
              <span class="heading2" id="s2.1">如何发布文章</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i2.2"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a2.2" @click="Jump('如何发布快讯')" target="hmcontent">
              <span class="heading2" id="s2.2">如何发布快讯</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i2.3"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a2.3" @click="Jump('如何成为知识网红')" target="hmcontent">
              <span class="heading2" id="s2.3">如何成为知识网红</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        class="heading1"
        id="i3"
        data-bg="cicon1.png;cicon2.png"
        style="
          background: url(cicon2.png) no-repeat;
          cursor: pointer;
          padding-left: 20px;
        "
      >
        <a class="heading1" id="a3" @click="Jump('智能问答')" target="hmcontent">
          <span class="heading1" id="s3">智能问答</span>
        </a>
        <ul id="ul3" style="list-style-type: none">
          <li
            class="heading2"
            id="i3.1"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a3.1" target="hmcontent" @click="Jump('如何发布问题')">
              <span class="heading2" id="s3.1">如何发布问题</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i3.2"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a3.2" @click="Jump('如何回答问题')" target="hmcontent">
              <span class="heading2" id="s3.2">如何回答问题</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i3.3"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a3.3" @click="Jump('如何采纳问题')" target="hmcontent">
              <span class="heading2" id="s3.3">如何采纳问题</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i3.4"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a3.4" @click="Jump('如何引用回答')" target="hmcontent">
              <span class="heading2" id="s3.4">如何引用回答</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        class="heading1"
        id="i4"
        data-bg="cicon1.png;cicon2.png"
        style="
          background: url(cicon2.png) no-repeat;
          cursor: pointer;
          padding-left: 20px;
        "
      >
        <a class="heading1" id="a4" @click="Jump('资源交易')" target="hmcontent">
          <span class="heading1" id="s4">资源交易</span>
        </a>
        <ul id="ul4" style="list-style-type: none">
          <li
            class="heading2"
            id="i4.1"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a4.1" @click="Jump('如何发布资源')" target="hmcontent">
              <span class="heading2" id="s4.1">如何发布资源</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i4.2"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a4.2" @click="Jump('个性化订阅')" target="hmcontent">
              <span class="heading2" id="s4.2">个性化订阅</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        class="heading1"
        id="i5"
        style="background: url(cicon9.png) no-repeat; padding-left: 20px"
      >
        <a class="heading1" id="a5" @click="Jump('跨库检索')" target="hmcontent">
          <span class="heading1" id="s5">跨库检索</span>
        </a>
      </li>
      <li
        class="heading1"
        id="i6"
        style="background: url(cicon9.png) no-repeat; padding-left: 20px"
      >
        <a class="heading1" id="a6" @click="Jump('基础功能')" target="hmcontent">
          <span class="heading1" id="s6">基础功能</span>
        </a>
      </li>
      <li
        class="heading1"
        id="i7"
        data-bg="cicon1.png;cicon2.png"
        style="
          background: url(cicon2.png) no-repeat;
          cursor: pointer;
          padding-left: 20px;
        "
      >
        <a class="heading1" id="a7"   target="hmcontent">
          <span class="heading1" id="s7">个人中心</span>
        </a>
        <ul id="ul7" style="list-style-type: none">
          <li
            class="heading2"
            id="i7.1"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a7.1" @click="Jump('如何查看账单')" target="hmcontent">
              <span class="heading2" id="s7.1">如何查看账单</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i7.2"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a7.2" @click="Jump('如何查看评论')" target="hmcontent">
              <span class="heading2" id="s7.2">如何查看评论</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i7.3"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a7.3" @click="Jump('积分规则')" target="hmcontent">
              <span class="heading2" id="s7.3">积分规则</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i7.4"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a7.4" @click="Jump('如何充值知识币')" target="hmcontent">
              <span class="heading2" id="s7.4">如何充值知识币</span>
            </a>
          </li>
          <li
            class="heading2"
            id="i7.5"
            style="background: url(cicon9.png) no-repeat; padding-left: 20px"
          >
            <a class="heading2" id="a7.5" @click="Jump('如何个性化订阅设置')" target="hmcontent">
              <span class="heading2" id="s7.5">如何个性化订阅设置</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <div class="left">
      <div class="top">{{name}}</div>
      <div class="left_gd">
        <router-view style="padding:0 10px" />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return { name: "" };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.name != from.query.name) {
        this.name = this.$route.query.name;
      }
    }
  },
  created() {
    this.name = this.$route.query.name?this.$route.query.name:'智荟网';
  },
  methods: {
    Jump(name) {
      let path = name == "智荟网" ? "help_list" : "/help_main";
      this.$router.push({
        path: path,
        query: {
          name: name
        }
      });
    }
  }
};
</script>
<style scoped>
.right {
  width: 15%;
  list-style-type: none;
  display: block;
  padding-left: 0;
  /* border: 1px solid #444343; */
  height: 95vh;
  display: inline-block;
  box-shadow: 1px 2px 13px 0px rgba(1, 29, 71, 0.44);
}
.right,
.left {
  /* float: left; */
  vertical-align: top;
}
.top {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #8e9090;
  color: #ffffff;
  font-size: 30px;
  padding: 0 30px;
}
.left_gd {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.left {
  width: 80%;
  height: 95vh;
  display: inline-block;
  overflow: hidden;
  padding-bottom:20px ;
}
ul li {
  line-height: 18px;
}
body {
  background: #fff;
}
.navbar {
  font-size: 120%;
}

.heading1 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}
.heading2 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}
.heading3 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}
.heading4 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}
.heading5 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}
.heading6 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
}

.hilight1 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}
.hilight2 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}
.hilight3 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}
.hilight4 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}
.hilight5 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}
.hilight6 {
  font-family: Arial, Helvetica;
  font-weight: normal;
  font-size: 10pt;
  color: #ffffff;
  background: #002682;
  text-decoration: none;
}

#toc {
  padding: 0;
  margin: 0;
}
#toc li {
  margin-top: 2px;
}
#toc ul {
  padding-left: 0;
}
</style>